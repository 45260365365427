<template>
  <div class="notice">
    <cms-header></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_solution">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>

          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link to="/help">
            <a class="now_a left" href="#">解决方案</a>
          </router-link>
        </div>
      </div>
      <div class="cms-container solution-content">
        <p class="solution-title"> {{ solutionList[current].title }}·解决方案</p>
        <p class="solution-span">{{ solutionList[current].description }}</p>
      </div>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left" style="width: 100%">
        <ul class="tab_con clearfix">
          <li v-for="(item,index) in solutionList" :key="index">
            <a class="tab_li" :class="current == index ? 'tab_active':''" @click="changeTab(index)">{{ item.title }}</a>
          </li>
        </ul>
        <div class="solution_con">
          <div v-for="(item,i) in solutionList" :key="'solution'+i" >
            <img v-if="current == i" class="w-100" style="cursor: pointer" @click="changeSolution()" :src="item.bgUrl[0].url"/>
          </div>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
import axios from "axios";
import {MessageBox} from "element-ui";
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "helpDetails",
  components: {
    CmsFoot,
    CmsHeader,
  },
  data() {
    return {
      current: 0,
      introductionTypeList: ['招标与企业采购', '物资竞卖/处理', '跨平台商城直采'],
      introductionContentList: ['规范、高效、智慧的第三方数字招标采购平台', '公开透明，规范高效，实现资产保值，增值', '标准产品一站式购买，降低成本，省时省心省力'],
      activeClass: 0,
      solution: {},
      solutionList: [],
      userLoginFlag: false,
      userInfo: {},
    }
  },
  methods: {
    changeSolution:function () {
      let that = this;
      let uuid = this.getCookie("uuid");
      if (that.current == 3){
        let that = this;
        getUserInfo(that.member).then(function (res) {
          that.userInfo = res.data.data;
          if (that.userInfo.isLogin =='1') {
            that["userLoginFlag"] = true;
            getUserInfo(that.member).then(function (res) {
              that.userInfo = res.data.data;
              //电子保函
              if (that.userLoginFlag) {
                //验证用户是否登录,这里是已经登录
                that.$http.post(that.member + "/api/fee/csBank/submitApplyPreApproval.htm?no_sitemesh", {
                  "companyId": that.userInfo.companyId
                }).then(function (res) {
                  let data = res.data;
                  if (data.isSuccess !='1' || data.resultData.code =="1"){
                    that.$notify.error({
                      title: '错误',
                      message: '操作失败'
                    });
                  }else if (data.resultData.code == "0") {
                    if (data.resultData.data.flaghavelmt == "0"){
                      let message = "该客户没有授信额度，是否跳转到长沙银行电子保函额度申请页面？";
                      MessageBox.confirm(message, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        window.open(data.resultData.data.redirecturl)
                      }).catch(() => {

                      })
                    }else {
                      MessageBox.confirm("您当前还剩余额度："+data.resultData.data.approveamount+"元，额度到期时间："+data.resultData.data.enddate+"，是否继续申请授信额度？", '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        window.open(data.resultData.data.redirecturl)
                      }).catch(() => {

                      })
                    }
                  }
                })
              } else {
                // 用户未登录,给
                MessageBox.confirm('您还未登录, 是否去登录?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  window.open(this.member + "/member/login/executeLogin.htm")
                }).catch(() => {

                })
              }
            }).catch(function (err) {
              console.log(err);
            });
          }else {
            that["userLoginFlag"] = false;
            // 用户未登录,给
            MessageBox.confirm('您还未登录, 是否去登录?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              window.open(this.member + "/member/login/executeLogin.htm")
            }).catch(() => {
            })
          }
        }).catch(function (err) {
          console.log(err);
        });

      }

    },
    changeTab(index) {
      this.current = index;
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      let that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        let categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (let i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].bgUrl != null && data.data.result[i].bgUrl != "") {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
            }
          }
          that[entityListName] = data.data.result;
        })
      })
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
  },
  created() {
    if (this.$route.query.current == "" || this.$route.query.current == null) {
      this.current = 0;
    } else {
      this.current = this.$route.query.current;
    }
    this.selectPromotionList("solution", "solution", "solutionList");
  }
}
</script>

<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
<style scoped>
body {
  background-color: #FFFFFF !important;
}

.width_w {
  display: none;
}

.solution-content {
  padding-top: 50px;
  padding-left: 30px;
  color: #FFFFFF;
}

.child_bg_solution {
  width: 100%;
  position: relative;
  height: 180px;
  background: url('../../assets/image/pc/solution-bg.png') center top no-repeat;
  background-size: cover;
  z-index: 0;
}


.solution-title {
  font-size: 2.5rem;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.solution-span {
  font-size: 1.7rem;
  margin-bottom: 0px;
}


.width_w {
  width: 100% !important;
}

.notice-left {
  width: 100% !important;
}
</style>
